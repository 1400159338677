import Helpers from '../lib/helpers';

class ContainersDeliveredMap {
  constructor({ selector }) {
    // Check for existence of selector
    this.selector = selector; // [data-containers-delivered-section]
    this.selectors = document.querySelectorAll(selector);
    if (!this.selectors) { return null; }

    this.tabTotalButtons = document.querySelectorAll('.containers-delivered li > a');
    this.tabbedInterfaceButtons = document.querySelectorAll('[data-containers-delivered-tab-button]');
    this.thisYearQueryString = '#this-year';
    this.firstTotalOpen = false;

    this.run();
  }

  run() {
    const pageLoadQueryString = window.location.search;

    // Reloading the page leaves the URL and correct tab open
    // but it doesn't swap to the "This Year" map numbers
    // So first check if we're on the "This Year" tab
    if (pageLoadQueryString === this.thisYearQueryString) {
      // Set initial data-states for second tab
      Helpers.forEach(this.tabbedInterfaceButtons, (tabButton) => {
        // For each tabbed interface button, find it's queryString
        const tabbedInterfaceButtonURL = tabButton.getAttribute('href');

        // Find the one with the queryString for this year
        if (tabbedInterfaceButtonURL === this.thisYearQueryString) {
          const tabButtonID = tabButton.getAttribute('id');

          // Find section with same ID
          const tabbedInterfaceWrapper = tabButton.closest('[data-tabbed-interface]');
          const tabSelector = `[aria-labelledby="${tabButtonID}"]`;
          const tabToFocus = tabbedInterfaceWrapper.querySelector(tabSelector);

          // Select first instance of data-containers-total within showing tab
          const firstTotal = tabToFocus.querySelector(this.selector);

          // Set the first total's data-state to open
          firstTotal.setAttribute('data-state', 'open');

          // Show the corresponding numbers on the map
          const mapTarget = firstTotal.getAttribute('data-map-target');
          ContainersDeliveredMap.setMapTargets(mapTarget);
        }
      });
    } else {
      // Set initial data-states for first tab
      Helpers.forEach(this.selectors, (total) => {
        const parentSection = total.closest('section');
        // For each total select it's parent <section> if it's not hidden
        if (!parentSection.getAttribute('hidden') && !this.firstTotalOpen) {
          // Set all totals in all tabs to data-state="closed"
          this.setAllTotalsToClosed();

          // Select first instance of data-containers-total within showing tab
          const firstTotal = parentSection.querySelector(this.selector);

          // Set the first total's data-state to open
          firstTotal.setAttribute('data-state', 'open');

          // Set firstTotalOpen to true now, so we don't run this again on other totals
          this.firstTotalOpen = true;
        }
      });
    }

    // Attach events for each of the tabbed interface buttons
    Helpers.forEach(this.tabTotalButtons, (el) => {
      // Select tab buttons
      el.addEventListener('click', () => {
        // when a tab is pressed find it's corresponding section
        const tabSelector = `#${el.getAttribute('href').substring(1)}`;
        const correspondingSection = document.querySelector(tabSelector);

        // Set all totals in all tabs to data-state="closed"
        this.setAllTotalsToClosed();

        // Find it's first total
        const firstTotal = correspondingSection.querySelector(this.selector);

        // Set the first total's data-state to open
        firstTotal.setAttribute('data-state', 'open');

        // Show the corresponding numbers on the map
        const mapTarget = firstTotal.getAttribute('data-map-target');
        ContainersDeliveredMap.setMapTargets(mapTarget);
      });
    });

    // Attach events for each of the section total buttons
    Helpers.forEach(this.selectors, (total) => {
      // Handle clicking of totals
      total.addEventListener('click', (e) => {
        // Set all totals to data-state="closed"
        this.setAllTotalsToClosed();

        // Set this total's data-state to open
        e.currentTarget.setAttribute('data-state', 'open');

        // Show the corresponding numbers on the map
        const mapTarget = e.currentTarget.getAttribute('data-map-target');
        ContainersDeliveredMap.setMapTargets(mapTarget);
      });
    });
  }

  setAllTotalsToClosed() {
    // Set all totals in all tabs to data-state="closed"
    Helpers.forEach(this.selectors, (total) => {
      total.setAttribute('data-state', 'closed');
    });
  }

  static setMapTargets(mapTarget) {
    const mapNumberSelector = `[data-map-number-${mapTarget}]`;
    const mapNumbers = document.querySelectorAll(mapNumberSelector);
    const allMapNumbers = document.querySelectorAll('.containers-delivered__number > span');

    // Set all map numbers to hidden
    Helpers.forEach(allMapNumbers, (el) => {
      el.setAttribute('hidden', '');
    });

    // Set corresponding map numbers to show
    Helpers.forEach(mapNumbers, (el) => {
      el.removeAttribute('hidden');
    });
  }
}

export default ContainersDeliveredMap;
